.label-image {
  display: flex;
  position: relative;
}

.label-image__background {
  border-radius: rem(6px);
  height: 100%;
  width: 100%;
}

.label-image__position {
  border-radius: 6px;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.image-container {
  display: flex;
  justify-content: center;
}

.label-image__text {
  align-items: center;
  border-radius: 0 0 6px 6px;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 0 rem($andes-spacing-24);
  position: absolute;
  right: 0;
  text-transform: uppercase;
  width: 100%;
}

.label-image__text--custom {
  opacity: 0.8;

  p {
    opacity: 1;
    text-align: center;
  }
}

.label-image__text--dark {
  background-color: rgba(0, 0, 0, 0.65);
  color: $andes-white;
}

.label-image__text--light {
  background-color: rgba(255, 255, 255, 0.85);
  color: $andes-black;
}

.label-image--behavior-hide .label-image__text {
  display: none;
}

.label-image__material {
  color: $andes-black;
  width: 100%;
}

.label-image__material--large {
  display: flex;
  flex-direction: column;
}
