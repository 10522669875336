.message-container {
  align-items: center;
  display: flex;
  height: rem($andes-spacing-40);
  justify-content: center;
  padding: rem($andes-spacing-8) 0;
  width: 100%;

  .message-container__text {
    color: $andes-gray-900-solid;
    font-size: rem($font-size-16);
    margin-left: rem($andes-spacing-12);

    button {
      margin-left: rem($andes-spacing-8);
    }

    .message-container__button {
      font-size: rem($font-size-16);
      font-weight: $font-weight-semibold;
    }
  }

  &--info {
    background: rgba(65, 137, 230, 0.1);
  }

  &--warning {
    background: $andes-orange-400;
  }

  &--error {
    background: rgba(249, 49, 35, 0.1);
  }
}
