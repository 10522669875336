$mdu: 8px;

$font-size-16: 16px;
$font-size-36: 36px;
$font-size-40: 40px;
$font-size-48: 48px;

$rrss-icons-scale: scale(1.3);

// as defined in Andes
$standard-line-height: 1;
$paragraph-line-height: 1.25;

$small-header-height: 64px;
$large-header-height: 90px;

$small-header-height-tools: 64px;
$large-header-height-tools: 138px;

$small-header-height-fashion: 64px;
$large-header-height-fashion: 90px;
$large-header-height-fashion-solid: 120px;

$small-header-height-material: 80px;
$large-header-height-material: 110px;

$flash-component-link-font-size-mobile: 10px;

$flash-component-height: 33px;
$flash-component-height-tools: 33px;
$flash-component-height-fashion: 33px;

// this value is the result of adding half of the paymentMethods component height
// to the height of the desktop banner, and substracting the paymentMethods padding top
$payment-methods-top-with-banner: 198px;
$payment-methods-button-with-banner: 274px;

// This picture have all payment-icons
$payment-legals-text-color: #777;
$payment-title-text-color: #333;
$payment-border-color: #ddd;
$payment-logo-width: 88px;
$payment-short-info-width: 120px;

$bank-condition-card-width: 1120px;

$home-black: #000 !default;
$home-gray-100: rgba($home-black, 0.1);
$home-gray-250: rgba($home-black, 0.25);

// FIXME: Remove when updating andes
$andes-spacing-18: 18px;
