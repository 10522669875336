@import './site-shopping-info';

.site-shopping-info {
  .container {
    display: flex;
    justify-content: space-between;
    overflow: auto;
    padding: 0;
    width: rem(1200px);
  }

  .info-slide {
    width: rem(400px);

    & + .info-slide:before {
      background-color: #e2e2e2;
      content: '';
      display: block;
      height: rem(200px);
      left: 0;
      position: absolute;
      top: 50px;
      width: rem(1px);
    }
  }

  .info-slide:last-child h2, .info-slide__title {
    margin: rem($andes-spacing-20) 0;
  }

  a:hover {
    color: $andes-blue-500;
  }

  @media screen and (max-width: 769px) {
    display: flex;
    margin-top: rem(60px);
    width: 100vw;
  }
}

.home--material {
  .site-shopping-info {
    margin-bottom: rem($andes-spacing-32);
  }
}
