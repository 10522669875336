@import './common.scss';

.cancel-buy-landing__section {
  margin: rem($andes-spacing-16) auto rem($andes-spacing-32);
  padding: rem($andes-spacing-64);
  width: rem(784px);

  .cancel-buy-landing__card {
    padding: rem($andes-spacing-32);
  }
}
