$ml-gray-darker-2: #333;
$ml-gray-dark: #4a4a4a;

$screen-mobile: 768px;

$boxed-max-width: 1200px;

$fw-regular: 400;

$subtitle-size: 16px;

$carousel-width: $boxed-max-width - 80px;
