@import "core";

.ui-ms-condition-container {
  margin: rem($andes-spacing-16);
  padding: rem($andes-spacing-12) rem($andes-spacing-16) rem($andes-spacing-12);

  .condition-short-info {
    float: left;
    margin-right: rem($andes-spacing-48);

    .condition-sub-info {
      margin: rem($andes-spacing-24) 0;
    }

    .condition-name {
      margin-top: rem($andes-spacing-20);
    }
  }
}
