.price-and-image {
  background-color: $light-black;
  display: flex;
  flex-direction: column;
  user-select: none;

  &.dark {
    background-color: $light-black;

    .price-and-image__title {
      color: $andes-white;
      font-weight: $font-weight-bold;
    }
  }

  &.light {
    background-color: $andes-gray-070;

    .price-and-image__title {
      color: $light-black;
      font-weight: $font-weight-bold;
    }
  }
}

.price-and-image__row {
  display: flex;
  justify-content: center;
  margin-left: rem($andes-spacing-8);
  width: 100%;
}

.price-and-image__title {
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  width: 100%;
}

.price-and-image-empty-state {
  opacity: 0.7;
}

.price-and-image-empty-state:hover{
  opacity: 0.5;
}
