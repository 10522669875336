@import "core";

.card-with-image-price {
  flex: 1;
  margin-top: (3 * $mdu);
  max-width: (47.25 * $mdu);

  &:not(:last-child) {
    margin-right: (3 * $mdu);
  }
}
