@import 'core';

.label-image {
  margin-bottom: 3 * $mdu;
  max-width: 72.5 * $mdu;
}

.label-image--small {
  height: 29 * $mdu;
}

.label-image--large {
  height: 61 * $mdu;
}

.label-image__text {
  font-size: $font-size-24;
  height: 11.5 * $mdu;
  letter-spacing: 0.5 * $mdu;
  padding: 0 (6 * $mdu);
}

.label-image--behavior-hover {
  .label-image__text {
    height: 0;
    transition: height 0.3s ease-out, visibility 0.2s ease-out;
    visibility: hidden;
  }

  &:hover .label-image__text {
    height: 11.5 * $mdu;
    transition: height 0.3s ease-in-out, visibility 0.4s ease-in-out 0.1s;
    visibility: visible;
  }
}

.label-image__material-title {
  // FIXME: these vendor prefixes props are needed to put ellipsis in multiline text
  // today at 2021 they have 95% browser support, for more info see https://caniuse.com/?search=-webkit-line-clamp
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  margin-bottom: $andes-spacing-20;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.label-image__material-divider {
  border-top: 2px solid;
  width: $andes-spacing-32;
}

.label-image__material-button {
  margin: $andes-spacing-24 $andes-spacing-32 $andes-spacing-40 $andes-spacing-40;

  & button {
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid;
    cursor: pointer;
    font-size: $font-size-18;
    height: 54px;
    width: 180px;
  }
}

.label-image__material-header {
  margin: $andes-spacing-40 $andes-spacing-40 $andes-spacing-24 $andes-spacing-40;
}

.label-image__material-background--large {
  border-radius: 0;
  height: 254px;
}

.label-image__material--small {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 116px);

  .label-image__material-title {
    max-width: 210px;
  }
}

.label-image__material--large .label-image__material-title {
  max-width: 480px;
}

.label-image__material-background--small {
  border-radius: 0;
  grid-column: 2;
  grid-row: -1 / -3;
}
