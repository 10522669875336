@import '../../../styles/variables';

.legal-notices__info-section {
  background-color: $andes-white;
  padding: ($mdu * 8) 0 ($mdu * 24);

  .legal-notices__info-container {
    margin: 0 auto;
    max-width: $legal-notices-view-max-width;
  }
  
  .title-section__subtitle {
    font-size: $font-size-20;
    margin-top: $mdu;
    max-width: $legal-notices-content-max-width;
  }

  .title-section__last-update {
    color: $andes-gray-900-solid;
    font-size: $font-size-12;
    margin-top: ($mdu * 1.5);
    max-width: $legal-notices-content-max-width;
  }

  .title-section__text {
    font-size: $font-size-16;
    line-height: $paragraph-line-height;
    margin: ($mdu * 3) 0;
    max-width: $legal-notices-content-max-width;
  }

  .title-section__link {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    max-width: $legal-notices-content-max-width;
  }
}
