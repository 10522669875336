@import "../../../styles/variables";

.how-buy-landing__steps {
  .steps__step {
    text-align: center;

    .step__title {
      font-size: $font-size-20;
    }

    .step__text {
      color: $andes-gray-550-solid;
      font-size: $font-size-14;
    }
  }
}
