@import './common.scss';
@import '../components/Condition/styles/desktop.scss';

.bank-conditions-landing__section {
  margin: rem($andes-spacing-16) auto rem($andes-spacing-32);
  padding: rem($andes-spacing-32) rem($andes-spacing-64);
  width: fit-content;

  .bank-conditions-landing__card {
    margin: rem($andes-spacing-16);
    padding: rem($andes-spacing-4) rem($andes-spacing-12) rem($andes-spacing-64);
    width: rem($bank-condition-card-width);
  }
}
