@import '../../commons/core';
@import '../../commons/hidden';
@import '../../commons/variables';

.section-category-item__container {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(1, minmax(100%, 1fr));
  grid-template-rows: 1fr minmax(4.5rem, min-content);
  height: 100%;
  width: 100%;
}

.section-category-item__image-container {
  grid-column: 1/2;
  grid-row: 1/3;
}

.section-category-item__header {
  background: $color-gray-500;
  color: $andes-white;
  display: grid;
  gap: rem($andes-spacing-4);
  grid-column: 1/2;
  grid-row: 2/3;
}

.section-category-item__title {
  font-weight: $font-weight-regular;
  grid-column: 1/2;
  grid-row: 1/2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.section-category-item__price-container {
  align-items: center;
  display: flex;
  font-weight: $font-weight-regular;
  grid-column: 1/2;
  grid-row: 2/3;

  .section-category-item__subtitle {
    margin-right: rem($andes_spacing-8);
  }
}

.section-category-item__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.section-category-item__price {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.section-category-item__title--hidden,
.section-category-item__price--hidden {
  grid-template-rows: minmax(auto, 1fr) !important;

  .section-category-item__button-container,
  .section-category-item__price-container,
  .section-category-item__title {
    grid-row: 1/2;
  }

  .section-category-item__price-container,
  .section-category-item__title {
    align-self: center;
  }
}

.section-category-item {
  border-radius: rem(12px);
  box-shadow: 0px rem(6px) rem(16px) $andes-gray-100;
}

.section-category__fake-item .section-category-item__image-container {
  align-items: center;
  background: $andes-white;
  display: flex;
  grid-row: 1/2;
  justify-content: center;

  .section-category-item__image {
    height: 30%;
    width: auto;
  }
}
